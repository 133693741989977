import { type ChangeEvent, useCallback, useMemo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import * as Sentry from "@sentry/browser";
import cls from 'classnames';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useTimeoutFn } from 'react-use';
import Loader from '@/components/Loader';
import SearchIcon from '@/images/icons/search.svg?react';
import CoffeeIcon from '@/images/coffee.svg?react';
import ErrorIcon from '@/images/error-triangle.svg?react';
import useInboxStream from '@/hooks/useInboxStream';
import styles from './Inbox.module.scss';
import type { ShortInboxMessage } from '@/types/messages';
import { getMessages } from "@/services/api/inbox";

interface Column {
  name: string
  getValue: (props: ShortInboxMessage) => string | number
  styles?: string
}

interface Cell {
  id: string
  value: string | number
  styles?: string,
}

interface Row extends ShortInboxMessage {
  id: string,
  cells: Cell[]
}

const columns: Column[] = [
  {
    name: 'From name',
    getValue: (props) => props.from[0].name || '-',
    styles: styles.listCellEmail,
  },
  {
    name: 'From e-mail',
    getValue: (props) => props.from[0].address || '-',
    styles: styles.listCellEmail,
  },
  {
    name: 'Subject',
    getValue: (props) => props?.subject,
    styles: styles.listCellSubject,
  }, {
    name: 'Received',
    getValue: (props) => props?.received,
    styles: styles.listCellReceived,
  }
];

const SEARCH_DEBOUNCE_TIME = 200;
const REFRESH_INTERVAL = 5000;

export default function Inbox() {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get('query') ?? '';
  const [messages, setMessages] = useState<ShortInboxMessage[]>([]);
  const { name = '' } = useParams();

  const fetchMessages = useCallback(async () => {
    try {
      const response = await getMessages(name);
      if (!response.ok) {
        Sentry.captureMessage(`${response.statusText} (${response.status})`);
      } else {
        const messageList:ShortInboxMessage[] = await response.json()
        setMessages((prev: ShortInboxMessage[]) =>
            messageList.map((message) => {
              prev.find(({messageId}) => messageId === message.messageId)
              const index = prev.findIndex(({messageId}) => messageId === message.messageId);
              return index !== -1 ? prev[index] : message;
            }));
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [name]);

  const resetFetchMessagesTimeout = useTimeoutFn(fetchMessages, REFRESH_INTERVAL)[2];

  const handleMessagesUpdate = useCallback((messages: ShortInboxMessage[]) => {
    setMessages(messages);
    resetFetchMessagesTimeout();
  }, []);

  const { isLoading, hasUnexpectedServerError } = useInboxStream(handleMessagesUpdate);

  useEffect(() => {
    fetchMessages();
  }, [fetchMessages]);

  const processedMessages = useMemo(() => {
    const result: { unseen: number, headers: Column[], rows: Row[] } = { unseen: 0, headers: columns, rows: [] };
    const filteredMessages = !query ? messages : messages.filter((message) => {
      return message.subject.includes(query);
    });

    if (filteredMessages.length) {
      result.unseen = filteredMessages.filter(({ unseen }) => unseen).length;
      result.rows = filteredMessages.map((message: ShortInboxMessage) => {
        const cells = columns.map((column) => ({ id: column.name, value: column.getValue(message), ...column }));
        return {
          ...message,
          id: message.messageId,
          cells,
        }
      }).sort((a, b) => (a < b ? 1 : -1));
    }
    return result;
  }, [messages, query]);

  const handleSearch = useMemo(() => debounce((event: ChangeEvent<HTMLInputElement>) => {
    setSearchParams({ query: event.target.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, SEARCH_DEBOUNCE_TIME), []);

  const renderContent = () => {
    if (hasUnexpectedServerError) {
      return (
        <div className={styles.errorState}>
          <ErrorIcon />
          Something went wrong.<br />
          Try to refresh the page after short while.
        </div>
      );
    }

    if (!messages.length) {
      return (
        <div className={styles.emptyState}>
          <CoffeeIcon />
          <span className={styles.emptyStateLoader}>New messages will appear automatically as they arrive</span>
        </div>
      );
    }

    return processedMessages.rows.map((row) => (
      <Link
        key={row.id}
        className={cls(styles.listRow, { [styles.listRowUnseen]: row.unseen })}
        to={`/${name}/messages/${row.id}/`}
      >
        {row.cells.map((cell) => (
          <div key={cell.id} className={cls(styles.listCell, cell.styles)}>
            <span>{cell.value}</span>
          </div>
        ))}
      </Link>
    ));
  }

  return (
    <>
      <Helmet titleTemplate="Bugbug Inbox / %s">
        <title>{name}</title>
        <meta name="robots" content="noindex" />
        <meta name="googlebot" content="noindex" />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>
            Inbox
            {processedMessages.unseen ? <span>{processedMessages.unseen}</span> : null}
          </h1>
          <div className={styles.search}>
            <SearchIcon />
            <input placeholder="Search..." defaultValue={query} onChange={handleSearch}/>
          </div>
        </div>
        <div role="table" className={styles.list}>
          <div className={styles.listHeader}>
            <div className={styles.listRow}>
              {processedMessages.headers.map((header) => (
                <div key={header.name} className={cls(styles.listCell, header.styles)}>
                  {header.name}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.listBody}>
            { isLoading ? (
              <div className={styles.loaderContainer}>
                <Loader />
                Loading messages
              </div>
            ) : renderContent()}
          </div>
        </div>
      </div>
    </>
  )
}
